import store from './index';
// redux 中数据持久化处理 -- 第一次进入系统或刷新
import { setUserInfo, clearUserInfo } from '@/reducer/userinfo';
import cache from '@/utils/cache';

const userInfo = cache().get('userinfo');
if (userInfo) {
  // 续签和过期处理
  if (Date.now() < userInfo.time + 1000 * 60 * 60) {
    userInfo.time = Date.now();
    store.dispatch(setUserInfo(userInfo));
  } else {
    store.dispatch(clearUserInfo());
  }
}
