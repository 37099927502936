/*
 * @Author: dupeicheng dupeicheng@sinsegye.com.cn
 * @Date: 2024-08-02 10:36:48
 * @LastEditors: dupeicheng dupeicheng@sinsegye.com.cn
 * @LastEditTime: 2024-08-02 11:10:47
 */
import { loginApi } from './apis';
import { post } from '@/utils/request';

/**
 * 登录请求
 * @param {data} loginName 用户名 password 密码
 * @returns Promise<requestBaseData.IBaseData<userType.Userinfo>>
 */
export const loginApiRequest = async (
  data: userType.LoginInfo
): Promise<requestBaseData.IBaseData<userType.Userinfo>> => await post(loginApi, data);
