/*
 * @Author: dupeicheng dupeicheng@sinsegye.com.cn
 * @Date: 2024-08-01 14:49:25
 * @LastEditors: dupeicheng dupeicheng@sinsegye.com.cn
 * @LastEditTime: 2024-08-08 17:43:13
 */
/*
 * @Author: zhanghongbin zhanghongbin@sinsegye.com.cn
 * @Date: 2024--08-01 17:00:36
 * @LastEditors: dupeicheng dupeicheng@sinsegye.com.cn
 * @LastEditTime: 2024-08-08 14:39:34
 */
export const loginApi = '/api/v1/Users/Login';

export const getMenu = '/api/v1/Menus';

export const getResoursePublish = '/api/v1/Resources/Publish';

export const getResourseList = '/api/v1/Resources';

export const getFilesList = '/api/v1/ObjectStorage/Files';

export const getUrl = '/api/v1/ObjectStorage/FileUrl';

export const getDirectory = '/api/v1/ObjectStorage/SubDirectory';

export const resourceTypes = '/api/v1/ResourceTypes';
