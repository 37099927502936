/* eslint-disable prettier/prettier */
/*
 * @Author: dupeicheng dupeicheng@sinsegye.com.cn
 * @Date: 2024-08-02 11:46:06
 * @LastEditors: dupeicheng dupeicheng@sinsegye.com.cn
 * @LastEditTime: 2024-08-05 13:33:08
 */
import { getMenuApi } from '@/apis/menuApi';
import cache from '@/utils/cache';
import { AppThunk } from '@/store';
import { createSlice } from '@reduxjs/toolkit';
import { RequestCodeEnum } from '@/enums/RequestCode';

const initialState: { menus: menuType.MenuItem[] } = {
  menus: cache().get('menus') || []
};
const menuInfo = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    setMenuInfo: (state, { payload }: { payload: menuType.MenuItem[] }) => {
      state.menus = payload;
      cache().set('menus', payload);
    },
    clearMenuInfo(state) {
      state.menus = initialState.menus;
      cache().remove('menus');
    }
  }
});

export const { setMenuInfo, clearMenuInfo } = menuInfo.actions;

// 异步中间件--获取菜单信息
export const getMenuAction = (): AppThunk<Promise<menuType.MenuItem[] | undefined>> => async dispatch => {
  const ret = await getMenuApi();
  if (ret.code === RequestCodeEnum.SUCCESS) {
    dispatch(setMenuInfo(ret.data as menuType.MenuItem[]));
  }
  return ret.data;
};
export default menuInfo.reducer;
