/*
 * @Author: dupeicheng dupeicheng@sinsegye.com.cn
 * @Date: 2024-08-01 15:13:15
 * @LastEditors: dupeicheng dupeicheng@sinsegye.com.cn
 * @LastEditTime: 2024-08-02 14:07:15
 */
import { configureStore } from '@reduxjs/toolkit';
import type { ThunkAction, Action } from '@reduxjs/toolkit';
import userinfo from '../reducer/userinfo';
import menuInfo from '@/reducer/menuInfo';
const store = configureStore({
  reducer: {
    userinfo,
    menuInfo
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
