/*
 * @Author: dupeicheng dupeicheng@sinsegye.com.cn
 * @Date: 2024-03-13 19:28:15
 * @LastEditors: dupeicheng dupeicheng@sinsegye.com.cn
 * @LastEditTime: 2024-08-05 11:14:56
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';

// 路由
import RouterConfig from '@/router';
// redux
import { Provider } from 'react-redux';
import store from './store';
import './store/redux-presist';

// antd本地化处理
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
// 日期处理本地化
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
// 日期全局使用中文
dayjs.locale('zh-cn');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <RouterConfig />
    </ConfigProvider>
  </Provider>
);
