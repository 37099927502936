/*
 * @Author: dupeicheng dupeicheng@sinsegye.com.cn
 * @Date: 2024-08-02 11:12:02
 * @LastEditors: dupeicheng dupeicheng@sinsegye.com.cn
 * @LastEditTime: 2024-11-04 09:58:22
 */
import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { userLoginAction } from '@/reducer/userinfo';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import './index.scss';
import { RequestCodeEnum } from '@/enums/RequestCode';
const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = useAppSelector(state => state.userinfo.userinfo.token);
  if (token) {
    return <Navigate to='/' replace />;
  }
  const loginEvent = async (values: userType.LoginInfo) => {
    setLoading(true);
    const ret = await dispatch(userLoginAction(values));
    if (ret.code === RequestCodeEnum.SUCCESS) {
      message.open({
        type: 'success',
        duration: 2,
        content: '登录成功',
        onClose: () => {
          navigate('/', { replace: true });
        }
      });
    } else {
      message.open({
        type: 'error',
        duration: 2,
        content: ret.message ?? '网络状态异常，请检查网络后重试！',
        onClose: () => {
          setLoading(false);
        }
      });
    }
  };
  return (
    <>
      <img className='login-img' src='../../../20240809-155615.jpg' alt='' />
      <div className='login-page'>
        <Form
          wrapperCol={{ span: 24 }}
          name='normal_login'
          className='login-form'
          initialValues={{ loginName: '', password: '' }}
          onFinish={loginEvent}>
          <div className='login-title'>资源管理系统</div>
          <Form.Item name='loginName' rules={[{ required: true, whitespace: true, message: '请输入账号!' }]}>
            <Input prefix={<UserOutlined className='site-form-item-icon' />} placeholder='账号' />
          </Form.Item>
          <Form.Item name='password' rules={[{ required: true, message: '请输入密码!' }]}>
            <Input prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='密码' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' className='login-form-button' loading={loading}>
              登录
            </Button>
          </Form.Item>
        </Form>
        <div className='login-footer'>
          <a className='login-footer-text' href=' https://beian.miit.gov.cn' target='_blank'>
            Copyright @2024 中科时代（深圳）计算机系统有限公司.All Rights Reserved. 粤ICP备2024263319号-2
          </a>
        </div>
      </div>
    </>
  );
};

export default Login;
