import React, { ReactNode, useEffect, useState } from 'react';
import { CreditCardFilled, DropboxCircleFilled, UserOutlined, PoweroffOutlined, HomeFilled } from '@ant-design/icons';
// import type { MenuProps } from 'antd';
import { Layout, Menu, Modal, message } from 'antd';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Outlet, RouteObject, useLocation, useNavigate } from 'react-router-dom';
import store from '@/store';
import { clearUserInfo } from '@/reducer/userinfo';
import { clearMenuInfo, getMenuAction } from '@/reducer/menuInfo';
import type { MenuProps } from 'antd';
import './index.scss';
type MenuItem = Required<MenuProps>['items'][number];
const { Header, Content, Sider } = Layout;

// 处理数据，把menuType.MenuItem[]转成MeunItem[]
export const processingMenuData = (
  data: menuType.MenuItem[],
  pathStart = '/',
  icon: ReactNode = <CreditCardFilled />
): MenuItem[] =>
  data.map(item => {
    const menuItem: MenuItem & { children?: MenuItem[] } = {
      key: pathStart + item.id,
      label: item.name,
      icon
    };

    if (item.children.length > 0) {
      menuItem.children = processingMenuData(item.children, pathStart + item.id + '/', <DropboxCircleFilled />);
    }

    return menuItem;
  });

export interface AppLayoutProps {
  setRoute: (data: menuType.MenuItem[]) => void;
  generatedRoutes: RouteObject[];
}
// type MeunItem = Required<MenuProps>['items'][number];
const Index: React.FC<AppLayoutProps> = ({ setRoute, generatedRoutes }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userinfo = useAppSelector(state => state.userinfo.userinfo);
  const menus = useAppSelector(state => state.menuInfo.menus);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = userinfo.token;
  const path = location.pathname;
  const nickName = userinfo.user.nickName;
  const fatherPath = '/' + path.split('/')[1];
  const navigate = useNavigate();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getMenu = async () => {
    const ret = await dispatch(getMenuAction());
    // 处理数据得到菜单数据
    if (!ret) {
      message.open({
        type: 'error',
        duration: 2,
        content: '获取菜单失败,请检查网络重试'
      });
      return;
    } else if (!compareRouteLength(ret)) {
      setRoute(ret);
    }
    setMenuItems([{ key: '/', label: '首页', icon: <HomeFilled /> }, ...processingMenuData(ret)]);
  };
  const compareRouteLength = (list: menuType.MenuItem[]) => {
    if (list.length !== generatedRoutes.length) {
      return false;
    }
    let result = true;
    list.forEach((item, index) => {
      if (
        (item.children.length > 0 && !generatedRoutes[index].children) ||
        (item.children.length === 0 && generatedRoutes[index].children)
      ) {
        result = false;
        return;
      }

      if (
        generatedRoutes[index]?.children &&
        item.children.length !== (generatedRoutes[index].children as RouteObject[]).length
      ) {
        result = false;
        return;
      }
    });
    return result;
  };
  useEffect(() => {
    if (fatherPath !== '/management') {
      getMenu().then(() => {
        if (menus.length === 0) return;
        if (!token) {
          // 判断是否非法进入本路由
          navigate('/login', { replace: true });
        }
      });
      return;
    }
    managementHandle(1);
    if (!userinfo.user.isAdmin || !token) {
      logoutHandle();
    }
  }, []);
  // 退出登录
  const logoutHandle = () => {
    store.dispatch(clearUserInfo());
    store.dispatch(clearMenuInfo());
    window.location.href = '/login';
  };
  // 点击菜单项跳转路由/选中时跳转路由
  const selectHandler = ({ key }: { key: string }) => {
    navigate(key);
  };

  // 资源管理点击事件
  const managementHandle = (type = 0) => {
    const managementMenus: menuType.MenuItem[] = [
      {
        id: 'management',
        name: '资源管理',
        children: [
          { id: 'sortManagement', name: '分类管理', children: [] },
          { id: 'resourceManagement', name: '资源管理', children: [] },
          { id: 'resourceTypeManagement', name: '软件类型管理', children: [] }
        ]
      }
    ];
    setMenuItems(processingMenuData(managementMenus));
    if (type == 0) {
      navigate('/management/sortManagement');
    }
  };
  return (
    <>
      <Layout className='resouseLayout'>
        <Header style={{ background: '#fff' }} className='lay-head'>
          <img src='../../..//main_logo.png' alt='' />
          <span>中科时代资源下载</span>
          <div className='head-user'>
            {userinfo.user.isAdmin ? (
              <span
                className='management-btn'
                onClick={e => {
                  if (e.currentTarget.innerText === '资源管理') {
                    managementHandle();
                  } else {
                    getMenu().then(() => {
                      if (menus.length === 0) return;
                      if (!token) {
                        // 判断是否非法进入本路由
                        navigate('/login', { replace: true });
                      }
                    });
                    navigate('/');
                  }
                }}>
                {fatherPath !== '/management' ? '资源管理' : '退出资源管理'}
              </span>
            ) : (
              <></>
            )}
            <UserOutlined />
            <span className='name'>{nickName}</span>
            <span className='logout-box' onClick={showModal}>
              <PoweroffOutlined className='logout-icon' />
              <span className='logout'>退出</span>
            </span>
          </div>
        </Header>
        <Layout>
          <Sider width={300}>
            <Menu
              mode='inline'
              className='resouseLayout-menu'
              defaultOpenKeys={[fatherPath]}
              defaultSelectedKeys={[path]}
              style={{
                height: '100%',
                borderRight: 0,
                backgroundColor: '#ffffff',
                boxShadow: '5px 0 5px -4px rgba(0,0,0,0.5)',
                fontSize: 16
              }}
              items={menuItems}
              onClick={selectHandler}
              onSelect={selectHandler}
            />
          </Sider>
          <Layout style={{ marginTop: 10 }}>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: '#fff'
              }}>
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
      <Modal
        keyboard={false}
        maskClosable={false}
        title='警告'
        open={isModalOpen}
        onOk={logoutHandle}
        onCancel={handleCancel}>
        <p>您确定要退出登录吗？</p>
      </Modal>
    </>
  );
};
export default Index;
