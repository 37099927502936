/* eslint-disable prettier/prettier */
/*
 * @Author: dupeicheng dupeicheng@sinsegye.com.cn
 * @Date: 2024-08-01 15:08:55
 * @LastEditors: dupeicheng dupeicheng@sinsegye.com.cn
 * @LastEditTime: 2024-08-13 10:48:56
 */
import { createSlice } from '@reduxjs/toolkit';
import cache from '@/utils/cache';
import { AppThunk } from '@/store';
import { loginApiRequest } from '@/apis/userApi';
import { RequestCodeEnum } from '@/enums/RequestCode';
const userInfo = createSlice({
  name: 'userinfo',
  initialState: {
    userinfo: {
      user: {
        id: '',
        loginName: '',
        nickName: cache().get('nickName') || '',
        isAdmin: cache().get('isAdmin') || false
      },
      token: cache().get('token') || ''
    }
  },
  reducers: {
    setUserInfo: (state, { payload }: { payload: userType.Userinfo }) => {
      state.userinfo = payload;
      cache().set('token', payload.token);
      cache().set('nickName', payload.user.nickName);
      cache().set('isAdmin', payload.user.isAdmin);
    },
    clearUserInfo(state) {
      state.userinfo = {
        user: {
          id: '',
          loginName: '',
          nickName: '',
          isAdmin: false
        },
        token: ''
      };
      cache().remove('token');
      cache().remove('nickName');
      cache().remove('isAdmin');
    }
  }
});

export const { setUserInfo, clearUserInfo } = userInfo.actions;
// 异步中间件--用户登录
export const userLoginAction =
  (data: userType.LoginInfo): AppThunk<Promise<requestBaseData.IBaseData<userType.Userinfo>>> =>
  async dispatch => {
    const ret = await loginApiRequest(data);
    if (ret.code === RequestCodeEnum.SUCCESS) {
      dispatch(setUserInfo(ret.data as userType.Userinfo));
    }
    return ret;
  };

export default userInfo.reducer;
