/*
 * @Author: dupeicheng dupeicheng@sinsegye.com.cn
 * @Date: 2024-08-02 11:49:19
 * @LastEditors: dupeicheng dupeicheng@sinsegye.com.cn
 * @LastEditTime: 2024-08-07 19:12:40
 */
import { get, del, post } from '@/utils/request';
import { getMenu } from './apis';
// import type { MenuProps } from 'antd';
// type MeunItem = Required<MenuProps>['items'][number];
export const getMenuApi = async (): Promise<requestBaseData.IBaseData<menuType.MenuItem[]>> => await get(getMenu);

export const delMenuApi = async (id: string): Promise<requestBaseData.IBaseData<boolean>> =>
  await del(getMenu + '/' + id);

export const addMenuApi = async (data: {
  name: string;
  order: number;
  parentId: string | null;
}): Promise<requestBaseData.IBaseData<boolean>> => await post(getMenu, data);
