class LocalStore {
  private _store: Storage;
  constructor(type = 'session') {
    this._store = window[(type + 'Storage') as 'sessionStorage' | 'localStorage'];
  }

  get(key: string, defaultValue = '') {
    const value = this._store.getItem(key);
    if (!value) {
      return defaultValue;
    }
    return JSON.parse(value);
  }

  set(key: string, value: any) {
    this._store.setItem(key, JSON.stringify(value));
    return this;
  }

  remove(key: string) {
    this._store.removeItem(key);
    return this;
  }

  has(key: string) {
    const value = this._store.getItem(key);
    return !!value;
  }

  clear() {
    this._store.clear();
  }
}

export default (type = 'session') => new LocalStore(type);
