/*
 * @Date: 2024-02-04 10:02:27
 * @LastEditTime: 2024-03-06 15:22:28
 * @Description:404界面
 * @FilePath: /zksd.machinemanagement.frontend/src/pages/HardwareConfig/notFound/index.tsx
 */
import React from 'react';
import './notFound.scss';
const Index = () => <div className='notFound-Title'>404,该页面不存在</div>;

export default Index;
