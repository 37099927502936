/*
 * @Author: dupeicheng dupeicheng@sinsegye.com.cn
 * @Date: 2024-08-01 14:49:25
 * @LastEditors: dupeicheng dupeicheng@sinsegye.com.cn
 * @LastEditTime: 2024-08-08 15:55:51
 */
import { createBrowserRouter } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import Login from '@/pages/login';
import { lazy, useState } from 'react';
import AppLayout from '../pages/appLayout';
import NotFound from '@/pages/notFound';
import cache from '@/utils/cache';
// import { useAppSelector } from '@/store/hooks';
import { LazyLoad } from './lazy';
import { RouterProvider } from 'react-router-dom';
const DownLoad = lazy(() => import('@/pages/dowloadPage'));
const Home = lazy(() => import('@/pages/home'));
const HisToryVersion = lazy(() => import('@/pages/historyVersion'));
const Management = lazy(() => import('@/pages/management'));
const SortManagement = lazy(() => import('@/pages/management/sortManagement'));
const ResourceManagement = lazy(() => import('@/pages/management/resourceManagement'));
const AddResource = lazy(() => import('@/pages/management/addResource'));
const ResourceTypeManagement = lazy(() => import('@/pages/management/resourceTypeManagement'));

const RouterConfig = () => {
  const menuItems: menuType.MenuItem[] = cache().get('menus');
  const [generatedRoutes, setGeneratedRoutes] = useState<RouteObject[]>([]);
  const routeDataHandle = (data: menuType.MenuItem[], pathStart = '/') => {
    const children: RouteObject[] = [];
    data.map(item => {
      children.push({
        path: pathStart + item.id,
        element: LazyLoad(<DownLoad />),
        children: item.children.length > 0 ? routeDataHandle(item.children, pathStart + item.id + '/') : []
      });
    });
    return children;
  };
  if (menuItems?.length === 0 || !menuItems) {
    window.history.replaceState({ page: 'login' }, 'Login', '/login');
  } else if (generatedRoutes.length === 0) {
    // 获取所有生成的路由
    setGeneratedRoutes(routeDataHandle(menuItems));
  }
  const setRouteFunc = (data: menuType.MenuItem[]) => {
    setGeneratedRoutes(routeDataHandle(data));
  };
  // 查找第一个子路径
  // const firstChildPath = generatedRoutes.length > 0 ? generatedRoutes[0].path : '/';
  const routers = createBrowserRouter([
    {
      path: '/',
      element: <AppLayout setRoute={setRouteFunc} generatedRoutes={generatedRoutes} />,
      children: [
        {
          index: true,
          element: LazyLoad(<Home />)
        },
        {
          path: '/historyVersion',
          element: LazyLoad(<HisToryVersion />),
          children: [{ index: true, element: LazyLoad(<HisToryVersion />) }]
        },
        {
          path: '/management',
          element: LazyLoad(<Management />),
          children: [
            { path: '/management/sortManagement', element: LazyLoad(<SortManagement />) },
            { path: '/management/resourceTypeManagement', element: LazyLoad(<ResourceTypeManagement />) },
            {
              path: '/management/resourceManagement',
              children: [
                { index: true, element: LazyLoad(<ResourceManagement />) },
                { path: '/management/resourceManagement/addResource', element: LazyLoad(<AddResource />) }
              ]
            }
          ]
        },
        ...generatedRoutes
      ]
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '*',
      element: <NotFound />
    }
  ]);

  return <RouterProvider router={routers} />;
};

export default RouterConfig;

// 自动引入路由
// const moduleFn = (require as any).context('./routers', false, /\.tsx$/);
// const children = moduleFn.keys().reduce((modules: RouteObject[], curr: string) => {
//   const value = moduleFn(curr).default;
//   if (Array.isArray(value)) {
//     modules.push(...value);
//   } else {
//     modules.push(value);
//   }
//   return modules;
// }, []);
